import { Fragment } from 'react';
import styled from 'styled-components';
import Link from 'components/atoms/Link';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0.4em 0;
`;

const SeparatorWrapper = styled.span`
  margin: 0 0.3em;
  color: ${({ theme }) => theme.colors.neutral4};
`;

const Separator = () => <SeparatorWrapper> / </SeparatorWrapper>;

const Breadcrumbs = ({ links }) => (
  <Wrapper>
    <StyledLink to="/">
      <strong>Главная</strong>
    </StyledLink>
    {links.map((link) => (
      <Fragment key={link.link}>
        <Separator />
        <StyledLink to={link.link}>{link.text}</StyledLink>
      </Fragment>
    ))}
  </Wrapper>
);

export default Breadcrumbs;
